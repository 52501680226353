<template>
  <hcc-modal
    name="template-detail-modal"
    :title="name"
    @opened="setStatus"
    @cancel="exitModal"
    @confirm="saveEdited"
  >
    <div class="template-detail__modal">
      <div class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.name") }}
        </div>
        <div
          class="template-detail__description"
          v-html="showNameDetails(temp.element_name)"
        ></div>
      </div>
      <div class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.type") }}
        </div>
        <div class="template-detail__description">
          {{ temp.settings.message_type }}
        </div>
      </div>
      <div class="template-detail__row-margin">
        <div class="template-detail__title">
          {{ $t("campaigns.data-bank.templates.category") }}
        </div>
        <div class="template-detail__description">{{ temp.category }}</div>
      </div>
      <div v-if="!isOwner" class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.status") }}
        </div>
        <div class="template-detail__description" :class="temp.status">
          {{ temp.status.toUpperCase() }}
        </div>
      </div>
      <hcc-select
        v-else
        v-model="status"
        option-label="name"
        :options="statusOption"
        :label="$t('common.status')"
        :placeholder="$t('common.status')"
        custom-class="add-template__row"
      />
      <div v-if="!isOwner" class="template-detail__row">
        <div class="template-detail__title">Namespace</div>
        <div class="template-detail__description">
          {{ namespaceValue(temp) }}
        </div>
      </div>
      <hcc-input
        v-else
        :placeholder="'namespace'"
        v-model.trim="temp.namespace"
        :label="'Namespace'"
        class="add-template__row"
      />
      <div class="template-detail__row--description">
        <hcc-textarea
          :label="$t('campaigns.data-bank.templates.description')"
          class="template-detail__description"
          :placeholder="temp.text"
          readonly
          :value="temp.text"
          :cols="63"
          name="Test_Textarea"
        />
      </div>
    </div>
    <template #footer v-if="!isOwner">
      <div class="template-detail__footer">
        <hcc-button color="muted" variant="outline" @click="exitModal">{{
          $t("common.exit")
        }}</hcc-button>
      </div>
    </template>
  </hcc-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import updateTemplate from '@/graphql/mutations/dataBank/updateTemplate.gql';
import errorHandler from '@/utils/error.handler';

export default {
  props: {
    detail: {
      type: Object,
    },
  },
  components: {
    HccModal, HccButton, HccTextarea, HccSelect, HccInput,
  },
  computed: {
    ...mapGetters({ isOwner: 'shared/isOwner' }),
    temp() {
      return this.detail != null ? this.detail : this.template;
    },
  },
  data() {
    return {
      status: '',
      statusOption: [{
        name: this.$t('campaigns.data-bank.templates.pending'),
        type: 'pending',
      }, {
        name: this.$t('campaigns.data-bank.templates.accepted'),
        type: 'active',
      }, {
        name: this.$t('campaigns.data-bank.templates.rejected'),
        type: 'rejected',
      }],
      name: this.$t('campaigns.data-bank.templates.detail'),
      template: {
        element_name: '',
        settings: {
          message_type: '',
        },
        status: '',
        text: '',
        namespace: '',

      },
    };
  },
  methods: {
    exitModal() {
      this.$modal.hide('template-detail-modal');
    },
    setStatus() {
      this.status = {
        name: this.$t(`campaigns.data-bank.templates.${this.temp.status.toLowerCase()}`),
        type: this.temp.status.toLowerCase(),
      };
    },
    async saveEdited() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: updateTemplate,
          variables: {
            namespace: this.temp.namespace,
            template: this.temp.id,
            status: this.status.type,
            element_name: this.temp.element_name,
            message_type: this.temp.settings.message_type,
          },
        });
        this.$emit('update', data.updateTemplate);
        this.$toasted.global.success(this.$t('campaigns.data-bank.templates.update-success', { name: this.temp.element_name }));
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      }
    },
    showNameDetails(text) {
      const size = text.length;
      const name = text.substring(0, size - 4);
      const key = text.substring(size - 4);
      return `${name}<b>${key}</b>`;
    },
    namespaceValue(props) {
      return props.namespace || 'Pending';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/dataBank/_templates_tab_data_bank.scss";
</style>
